var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "filled-cube-svg",
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M20.0005 7.19014C20.0266 7.17598 20.0483 7.15572 20.0633 7.13137C20.0784 7.10701 20.0863 7.07942 20.0863 7.05133C20.0863 7.02325 20.0784 6.99565 20.0633 6.9713C20.0483 6.94695 20.0266 6.92668 20.0005 6.91253L13.3915 3.34743C12.9687 3.11985 12.4886 3 11.9998 3C11.511 3 11.0309 3.11985 10.608 3.34743L4.00039 6.91253C3.97426 6.92668 3.95258 6.94695 3.93752 6.9713C3.92246 6.99565 3.91453 7.02325 3.91453 7.05133C3.91453 7.07942 3.92246 7.10701 3.93752 7.13137C3.95258 7.15572 3.97426 7.17598 4.00039 7.19014L11.9135 11.5121C11.9401 11.5267 11.9704 11.5344 12.0013 11.5344C12.0322 11.5344 12.0625 11.5267 12.0891 11.5121L20.0005 7.19014ZM3.25962 8.28329C3.23323 8.26914 3.20328 8.26171 3.1728 8.26176C3.14232 8.2618 3.1124 8.26932 3.08605 8.28355C3.05971 8.29778 3.03788 8.31821 3.02277 8.34278C3.00766 8.36736 2.99981 8.3952 3 8.4235V15.4095C3.00066 15.7461 3.09622 16.0767 3.27719 16.3685C3.45816 16.6602 3.71823 16.9028 4.03154 17.0723L11.0481 20.9785C11.0744 20.9926 11.1042 21 11.1346 21C11.1649 21 11.1948 20.9926 11.2211 20.9785C11.2474 20.9644 11.2692 20.9442 11.2844 20.9198C11.2996 20.8954 11.3077 20.8677 11.3077 20.8395V12.7342C11.3077 12.7061 11.2997 12.6784 11.2845 12.654C11.2693 12.6296 11.2474 12.6093 11.2212 12.5952L3.25962 8.28329ZM12.6923 12.7624V20.8375C12.6923 20.8657 12.7004 20.8933 12.7156 20.9177C12.7308 20.9422 12.7526 20.9624 12.7789 20.9765C12.8052 20.9906 12.8351 20.998 12.8654 20.998C12.8958 20.998 12.9256 20.9906 12.9519 20.9765L19.968 17.0703C20.2811 16.9011 20.5411 16.6587 20.7221 16.3674C20.9031 16.0761 20.9989 15.7458 21 15.4095V8.4235C20.9999 8.39534 20.9918 8.3677 20.9766 8.34335C20.9613 8.319 20.9395 8.29879 20.9132 8.28475C20.8869 8.27072 20.8571 8.26334 20.8268 8.26337C20.7964 8.26339 20.7667 8.27082 20.7404 8.2849L12.7788 12.6238C12.7526 12.6378 12.7308 12.658 12.7157 12.6823C12.7005 12.7067 12.6924 12.7342 12.6923 12.7624V12.7624Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }