
import Vue from 'vue'

export default Vue.extend({
  name: 'FilledCube',
  props: {
    color: {
      required: false,
      default: 'currentColor',
      type: String,
    },
  },
})
